import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'
import calcStyles from './calculators.module.scss'

import ParlayCalc from "../../components/Calculators/parlay_calculator"
import CalculatorDrop from "../../components/Title Drop/calculator_drop"



const ParlayCalculator = () => {

    return (
        <Layout>
            <Head
                title="Parlay Odds and Payout Calculator"
                pageDescription="A free calculator for determinig parlay payouts based on odds"
            />
                <CalculatorDrop
                    startingItem={'Parlay Payout Calculator'}
                />
                <p className={calcStyles.calculator_subtitle}>
                A free calculator for determinig parlay payouts based on odds
                </p>
                <ParlayCalc/>
                <div className={calcStyles.content_container}>
                    <h3 className={calcStyles.content_h3}>
                        What is a Parlay Bet
                    </h3>
                    <p className={calcStyles.content_p}>
                        A parlay bet multiplies a series of individual bets together into a single wager, creating the potential for higher payouts. While parlays do have higher payouts, they also carry more risk. If any of the underlying bets lose, the entire parlay loses.<br className={calcStyles.content_break}/>
                        For instance, two successful $50 wagers at -110 odds would pay $90.91 in profit, while one successful $100 parlay of the same bets would pay $264.47. However, if only one of the two underlying bets are successful, the parlay would lose all $100, while the individual bets would only lose $4.45 since the bettor is still paid $45.45 for the one bet that did win.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        How to calculate parlay payout and odds using the calculator
                    </h3>
                    <p className={calcStyles.content_p}>
                        Calculating parlay payouts can get complicated quickly, especially when more and more bets are combined. The free parlay calculator above does this payout math for you. Simply enter your parlay bet size and the odds of the bets included in your parlay, and the calculator will tell you the total potential payout.<br className={calcStyles.content_break}/>
                        The parlay calculator will also tell you the implied cover probability of the parlay based on the odds and the true cover probability of the parlay based on the odds with bookmaker vig removed (aka the fair odds).<br className={calcStyles.content_break}/>
                        As you may find using the calculator, multiplying together payouts also multiples bookmaker vig, so oftentimes the hold on parlays can be more unfavorable.
                    </p>
                </div>
        </Layout>
    )

}

export default ParlayCalculator