import React, { useState } from "react"
import DisplayParlay from "./helpers/display_parlay"

import calcStyles from './parlay_calculator.module.scss'

const ParlayCalc = () => {

    // set initial states //
    // list that holds bets //
    const [betList, setBetList] = useState([])
    // input for new odds //
    const [newOdds, setNewOdds] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [errorMsgVisible, setErrorMsgVisible] = useState(false)
    // validation for add button //
    const [addVisible, setAddVisible] = useState(false)
    // bet size //
    const [betSize, setBetSize] = useState(100)
    const [sizeMsg, setSizeMsg] = useState('')
    const [sizeMsgVisible, setSizeMsgVisible] = useState(false)


    // update text entries when value is wrong //
    const updateWithError = (activeEntry, rawEntry) => {
        
        if (activeEntry === 'odds') {
            // make add button invis //
            setAddVisible(false)
            // update errors //
            setErrorMsgVisible(true)
            setSizeMsgVisible(false)
            // update odds entry with user input //
            setNewOdds(rawEntry)
        }

        if (activeEntry === 'size') {
            // update errors //
            setErrorMsgVisible(false)
            setSizeMsgVisible(true)
            // update odds entry with user input //
            setBetSize(rawEntry)
        }

    }

    // if input valid, make add button functional //
    const updateWithValue = (activeEntry, rawEntry) => {

        if (activeEntry === 'odds') {
            // make add button invis //
            setAddVisible(true)
            // update error //
            setErrorMsgVisible(false)
            // update odds entry with user input //
            setNewOdds(rawEntry)

            // check for error on size //
            if (sizeMsg !== '') {
                setSizeMsgVisible(true)
            }
        }

        if (activeEntry === 'size') {
            // elim size msg
            setSizeMsgVisible(false)
            // update odds entry with user input //
            setBetSize(rawEntry)

            // check for error on bet amount //
            if (errorMsg !== '') {
                setErrorMsgVisible(true)
            }
        }

    }

    // if input is blank, clear error, but keep button invis //
    const updateWithBlank = (rawEntry) => {

        // make add button invis //
        setAddVisible(false)
        // update error //
        setErrorMsgVisible(false)
        // update odds entry with user input //
        setNewOdds(rawEntry)

        // check for error on size //
        if (sizeMsg !== '') {
            setSizeMsgVisible(true)
        }


    }

    // adding to list //
    const addToList = event => {

        // current length of list, which is also 0 index of new item //
        const itemIndex = betList.length
        
        // append //
        const newItem = {
            'itemIndex' : itemIndex,
            'itemValue' : newOdds
        }
        const newList = betList.concat(newItem)
        setBetList(newList)

        // clear odds //
        updateWithBlank('')
    }

    // removing from list //
    const removeFromeList = event => {

        // get index of item clicked //
        const itemIndex = event.target.getAttribute('data-itemindex')
        
        // copy list and remove item //
        const newList = [...betList]
        newList.splice(itemIndex,1)

        // reindex items //
        const reindexedList = newList.map((listItem, index) => {
            return ({
                'itemIndex' : index,
                'itemValue' : listItem['itemValue']
            })
        })
        // update list //
        setBetList(reindexedList)

    }

    // handle the actual field update //
    const updateOdds = event => {

        const odds = event.target.value
        const americanFormatted = parseFloat(odds).toFixed(1)
        
        if (odds === '') {
            setErrorMsg('')
            updateWithBlank(odds)
        } else if (isNaN(americanFormatted) || (americanFormatted < 100 & americanFormatted >-100)) {
            // alert msg if odds not valid //
            setErrorMsg('American Odds must be greater than 100 or less than -100')
            updateWithError('odds', odds)
        } else {
            setErrorMsg('')
            updateWithValue('odds', odds)
        }

    }

    const updateSize = event => {
        
        const risked = event.target.value
        const riskedValue = parseFloat(risked)

        if (risked === '') {
            setSizeMsg(`Original bet size can't be blank`)
            updateWithError('size', risked)
        } else if (isNaN(riskedValue) || (riskedValue <= 0)) {
            setSizeMsg(`Original bet size must be a number greater than 0`)
            updateWithError('size', risked)
        } else {
            setSizeMsg('')
            updateWithValue('size', risked)
        }
    }


    return (

        <div className={calcStyles.calc_wrapper}>
            <div className={calcStyles.parlay_container}>
                <div className={calcStyles.parlay_panel} data-alignment='left'>
                    <div className={calcStyles.param_container}>
                        <h2 className={calcStyles.title}>
                            Parlay Parameters
                        </h2>
                        <div className={calcStyles.param_box}>
                            <h3 className={calcStyles.param_title}>
                                Parlay Bet Size
                            </h3>
                            <div className={calcStyles.check_input_container}>
                                <div className={calcStyles.check_div} data-active={sizeMsgVisible}>
                                    {(sizeMsgVisible===false) ? '$' : '\u2297' }
                                </div>
                                <input className={calcStyles.input_value}
                                    id={'betSize'}
                                    name={'betSize'}
                                    type="text"
                                    value={betSize}
                                    onChange={updateSize}
                                />
                            </div>
                        </div>
                        <div className={calcStyles.param_box}>
                            <h3 className={calcStyles.param_title}>
                                {`Parlay Bet Odds (ie -110)`}
                            </h3>
                            <div className={calcStyles.bet_entry_div}>
                                <div className={calcStyles.check_input_container}>
                                    <div className={calcStyles.check_div} data-active={errorMsgVisible}>
                                        {(errorMsgVisible===false) ? '$' : '\u2297' }
                                    </div>
                                    <input className={calcStyles.input_value}
                                        id={'newOdds'}
                                        name={'newOdds'}
                                        type="text"
                                        value={newOdds}
                                        onChange={updateOdds}
                                    />
                                </div>
                                {
                                    addVisible
                                    ?
                                    <button
                                        className={calcStyles.add_button}
                                        onClick={addToList}
                                    >
                                        add
                                    </button>
                                    :
                                    <div className={calcStyles.add_div}>
                                        add
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={calcStyles.odds_list_div}>
                            <h3 className={calcStyles.odds_list_title}>
                                Parlay Bets
                            </h3>
                            {betList.map((listItem) => {
                                return (
                                    <div
                                        className={calcStyles.odds_list_item_div}
                                        key={listItem['itemIndex']}
                                    >
                                        <p className={calcStyles.odds_list_item_p}>
                                            {listItem['itemValue']}
                                        </p>
                                        <button
                                            className={calcStyles.odds_list_item_button}
                                            data-itemindex={listItem['itemIndex']}
                                            onClick={removeFromeList}
                                        >
                                            x
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <DisplayParlay
                    betSize={betSize}
                    betArray={betList}
                />
                <div className={calcStyles.alert_container} data-active={errorMsgVisible}>
                    <p className={calcStyles.alert} data-active={errorMsgVisible}>
                        {errorMsg}
                    </p>
                </div>
                <div className={calcStyles.alert_container} data-active={sizeMsgVisible}>
                    <p className={calcStyles.alert} data-active={sizeMsgVisible}>
                        {sizeMsg}
                    </p>
                </div>
            </div>
        </div>

    )

}

export default ParlayCalc
    