import React from "react"

import calcStyles from '../parlay_calculator.module.scss'

const DisplayParlay = ({ betSize, betArray }) => {
    
    var impliedOdds = 1
    var trueOdds = 1
    var numBets = 0
    var payout = betSize
    var showCalcs = true

    // helper funcs //
    // func for imperceisely handling float-point rounding hell //
    function truncate (num, places) {
        return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    }

    // will abreviate large numbers //
    function displayNum (num, isVis) {

        const parsedNum = parseFloat(num)

        if (isVis) {
            if (Math.abs(parsedNum) > 99999) {
                return (
                    `$${truncate(parsedNum/1000,0)}k`
                )
            } else if (Math.abs(parsedNum) > 9999) {
                return (
                    `$${truncate(parsedNum/1000,1)}k`
                )
            }
            else if (Math.abs(parsedNum) > 999) {
                return (
                    `$${truncate(parsedNum/1000,2)}k`
                )
            } else if (Math.abs(parsedNum) > 99) {
                return (
                    `$${truncate(parsedNum,1)}`
                )
            } else {
                return (
                    `$${truncate(parsedNum,2)}`
                )
            }
        } else {
            return(
                '--'
            )
        }
    }

    // convert odds to dec //
    const americanToDec = (odds) => {

        const parsedOdds = parseInt(odds)

        if (parsedOdds < 0) {
            return (
                1+(100/(-1 * parsedOdds))
            )
        } else {
            return (
                1 + (parsedOdds / 100)
            )
        }
    }

    const americanToPct = (odds) => {

        const parsedOdds = parseInt(odds)

        if (parsedOdds < 0) {
            return (
                (-1 * parsedOdds) / ((-1 * parsedOdds) + 100)
            )
        } else {
            return (
                100 /  (parsedOdds + 100)
            )
        }
    }

    // iter through array //
    if (betArray.length === 0) {

        impliedOdds = '--'
        trueOdds = '--'
        numBets = '--'
        payout = '--'
        showCalcs = false

    } else {

        betArray.forEach((item) => {
            // read in vals //
            const americanVal = parseInt(item['itemValue'])
            const pctVal = americanToPct(americanVal)
            const pctValAdj = pctVal * .95455
            const decVal = americanToDec(americanVal)

            // update calc variables //
            impliedOdds = impliedOdds * pctVal
            trueOdds = trueOdds * pctValAdj
            numBets = numBets + 1
            payout = payout * decVal

        })

    }

    return (
        <div className={calcStyles.parlay_panel} data-alignment='right'>
            <div className={calcStyles.param_container} data-alignment='bottom'>
                <div className={calcStyles.top_payout_div}>
                    <div className={calcStyles.profit_div}>
                        <p className={calcStyles.profit_val}>
                            {displayNum(payout - betSize, showCalcs)}
                        </p>
                        <p className={calcStyles.profit_text}>
                            Potential Profit
                        </p>
                    </div>
                    <h2 className={calcStyles.title}>
                        Total Payout
                    </h2>
                    <p className={calcStyles.payout_size}>
                        {displayNum(payout, showCalcs)}
                    </p>
                </div>
                <h2 className={calcStyles.title}>
                    Parlay Stats
                </h2>
                <div className={calcStyles.payout_stat_container}>
                    <div className={calcStyles.payout_stat_div}>
                        <p className={calcStyles.payout_stat}>
                            {showCalcs ? `${numBets}` : '--'}
                        </p>
                        <p className={calcStyles.payout_stat_text}>
                            Number of Bets
                        </p>
                    </div>
                    <div className={calcStyles.payout_stat_div}>
                        <p className={calcStyles.payout_stat}>
                            {showCalcs ? `${truncate(100*impliedOdds,2)}%` : '--'}
                        </p>
                        <p className={calcStyles.payout_stat_text}>
                            Implied Cover Probability
                        </p>
                    </div>
                    <div className={calcStyles.payout_stat_div}>
                        <p className={calcStyles.payout_stat}>
                            {showCalcs ? `${truncate(100*trueOdds,2)}%` : '--'}
                        </p>
                        <p className={calcStyles.payout_stat_text}>
                            True Cover Probability
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DisplayParlay